/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getUserByFilter, updateUserAttributes } from 'services/UserService';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Avatar from '@material-ui/core/Avatar';
import {
  getAllUserSetting,
  setUserSettingByKey
} from 'services/UserSettingService';
import theme from 'theme/theme';

const emailregex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneNumberRegex = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{8})$/;

class ProfileSettings extends Component {
  state = {
    disableSaveButton: true,
    userName: '',
    attributes: {
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      email: ''
    },
    languageValue: '',
    notification: {
      Import: {
        email: false,
        text: false
      },
      Iot: {
        email: false,
        text: false
      },
      Review: {
        email: false,
        text: false
      }
    }
  };

  componentDidMount() {
    this.getUserInformation();
    this.getUserSetting();
    this.setCurrentLanguage();
  }
  
  handelInputeValue = (event, name) => {
    let attributesUpdate = { ...this.state.attributes };
    attributesUpdate[name] = event.target.value;
    this.setState({ attributes: attributesUpdate, disableSaveButton: false });
  };

  getUserSetting = () => {
    getAllUserSetting((error, data) => {
      if (error) {
        console.log(error);
        return; // TODO snack
      }

      // build preference to ihm
      let notification = { ...this.state.notification };

      Object.keys(data.Notification).forEach((keyNotification) => {
        const categories = data.Notification[keyNotification];
        notification[keyNotification] = {};

        categories.forEach((keyMethod) => {
          notification[keyNotification][keyMethod] = true;
        });
      });

      this.setState({
        notification: notification
      });
    });
  };
  getUserInformation = () => {
    getUserByFilter('userId', this.props.auth.userId, (error, data) => {
      if (error) {
        console.log(error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.getUserInformation
        );
      } else {
        let attributes = {
          email: data[0].email ? data[0].email : '',
          phone: data[0].phone_number ? data[0].phone_number : '',
          firstName: data[0].name ? data[0].name : ' ',
          lastName: data[0].family_name ? data[0].family_name : '',
          company: data[0]['custom:company'] ? data[0]['custom:company'] : ''
        };
        this.setState({ attributes: attributes, userName: data[0].Username });
      }
    });
  };

  updateAttributes = () => {
    let attributes = [];

    Object.keys(this.state.attributes).forEach((key) => {
      attributes.push({ Name: key, Value: this.state.attributes[key] });
    });

    updateUserAttributes(this.state.userName, attributes)
      .then((response) => {
        this.props.snackBar.addSnackSuccess(
          this.props.multiLang.administration.success.updateAttributesSucceeded
        );
        this.setState({ disableSaveButton: true });
      })
      .catch(function (error) {
        console.log('erreur : ', error);
        this.props.snackBar.addSnackError(
          this.props.multiLang.administration.errors.updateAttributesFailed
        );
      });
  };

  handleCheckbox = (notificationMethod, checkboxName) => {
    let notification = { ...this.state.notification };
    notification[checkboxName][notificationMethod] = !notification[
      checkboxName
    ][notificationMethod];

    // build preference to save
    let notificationPreference = {};
    Object.keys(this.state.notification).forEach((keyNotification) => {
      const category = this.state.notification[keyNotification];
      notificationPreference[keyNotification] = [];

      Object.keys(category).forEach((keyMethod) => {
        if (category[keyMethod]) {
          notificationPreference[keyNotification].push(keyMethod);
        }
      });
    });

    setUserSettingByKey(
      'Notification',
      notificationPreference,
      (error, data) => {
        if (error) {
          return this.props.snackBar.addSnackError(
            this.props.multiLang.defaultModelError
          ); // TODO mettre la traduction
        }

        this.setState({
          notification: notification
        });
      }
    );
  };

  setCurrentLanguage = () => {
    let currentLanguage = localStorage.getItem('language');
    if (currentLanguage) {
      this.setState({
        languageValue: {
          label: this.props.multiLang.administration.language[currentLanguage],
          code: currentLanguage
        }
      });
    }
  };

  handleChangeLanguage = (event) => {
    this.setState({ languageValue: event.target.value });
  };

  confirmLanguage = () => {
    if (this.state.languageValue !== '') {
      localStorage.setItem('language', this.state.languageValue.code);
      window.location.reload(true);
    }
  };

  render() {
    return (
      <div>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper style={{ padding: 32 }}>
                <Typography variant='h6' display='block' gutterBottom>
                  {this.props.multiLang.administration.userInformation}
                </Typography>
                <form style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ flexGrow: 1 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          label={this.props.multiLang.administration.firstName}
                          fullWidth={true}
                          value={this.state.attributes.firstName}
                          onChange={(e) =>
                            this.handelInputeValue(e, 'firstName')
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          label={this.props.multiLang.administration.lastName}
                          fullWidth={true}
                          value={this.state.attributes.lastName}
                          onChange={(e) =>
                            this.handelInputeValue(e, 'lastName')
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          label={this.props.multiLang.administration.company}
                          fullWidth={true}
                          value={this.state.attributes.company}
                          onChange={(e) => this.handelInputeValue(e, 'company')}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          label={this.props.multiLang.administration.phone}
                          fullWidth={true}
                          value={this.state.attributes.phone}
                          onChange={(e) => this.handelInputeValue(e, 'phone')}
                          placeholder='ex: +33...'
                          helperText={
                            phoneNumberRegex.test(
                              String(this.state.attributes.phone).toLowerCase()
                            ) ? (
                              ''
                            ) : (
                              <span style={{ color: theme.color.red }}>
                                {this.props.multiLang.administration.errors.errorPhone}
                              </span>
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          label={this.props.multiLang.administration.email}
                          fullWidth={true}
                          value={this.state.attributes.email}
                          onChange={(e) => this.handelInputeValue(e, 'email')}
                          helperText={
                            emailregex.test(
                              String(this.state.attributes.email).toLowerCase()
                            ) ? (
                              ''
                            ) : (
                              <span style={{ color: theme.color.red }}>
                                {this.props.multiLang.administration.errors.errorEmail}
                              </span>
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={
                            !(
                              phoneNumberRegex.test(
                                String(
                                  this.state.attributes.phone
                                ).toLowerCase()
                              ) &&
                              emailregex.test(
                                String(
                                  this.state.attributes.email
                                ).toLowerCase()
                              )
                            )
                          }
                          onClick={this.updateAttributes}
                        >
                          OK
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 32 }}>
                <Typography variant='h6' display='block' gutterBottom>
                  {this.props.multiLang.administration.language.lang}
                </Typography>
                <form style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={3}
                      style={{ alignItems: 'flex-end' }}
                    >
                      <Grid item xs={12} sm={3} style={{ minWidth: 200 }}>
                        <FormControl fullWidth={true}>
                          <InputLabel htmlFor='age-simple'>
                            {
                              this.props.multiLang.administration.language
                                .chooseLanguage
                            }
                          </InputLabel>
                          <Select
                            value={this.state.languageValue}
                            onChange={this.handleChangeLanguage}
                            renderValue={(v) => v.label}
                          >
                            <MenuItem
                              style={{ height: 40 }}
                              value={{
                                label: this.props.multiLang.administration
                                  .language.fr,
                                code: 'fr'
                              }}
                            >
                              <Avatar
                                src='images/icons/flag/fr.svg'
                                style={{ marginRight: 20 }}
                              />{' '}
                              {this.props.multiLang.administration.language.fr}
                            </MenuItem>
                            <MenuItem
                              style={{ height: 40 }}
                              value={{
                                label: this.props.multiLang.administration
                                  .language.en,
                                code: 'en'
                              }}
                            >
                              <Avatar
                                src='images/icons/flag/gb.svg'
                                style={{ marginRight: 20 }}
                              />{' '}
                              {this.props.multiLang.administration.language.en}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={this.confirmLanguage}
                        >
                          {this.props.multiLang.administration.language.ok}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <div style={{ flexGrow: 1, padding: 32 }}>
                  <Typography variant='h6' display='block' gutterBottom>
                    {this.props.multiLang.administration.notificationTitle}
                  </Typography>
                  <Grid container>
                    {' '}
                    {/* spacing={5} style={{ margin: 20 }}>*/}
                    <Grid item xs={1} sm={1}>
                      <div />
                    </Grid>
                    <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}>
                      <Typography
                        variant='caption'
                        display='block'
                        gutterBottom
                      >
                        {this.props.multiLang.administration.notificationMail}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    {' '}
                    {/* spacing={5} style={{ margin: 20 }}>*/}
                    <Grid item xs={1} sm={1}>
                      <Typography
                        variant='caption'
                        display='block'
                        gutterBottom
                        style={{ paddingTop: 12 }}
                      >
                        {this.props.multiLang.administration.notificationImport}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} style={{ textAlign: 'center' }}>
                      <Checkbox
                        checked={this.state.notification.Import.email || false}
                        onChange={(e) => {
                          this.handleCheckbox('email', 'Import');
                        }}
                        value='Email Import'
                        color='primary'
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  multiLang: state.multiLang,
  auth: state.auth,
  snackBar: state.snackBar
});

export default connect(mapStateToProps)(ProfileSettings);
