/*
 *  Copyright © 2018-2020 Capgemini Technology Services. All Rights Reserved.
 *
 *  This file is part of commercial Software by Capgemini Technology Services,
 *  provided in accordance with the terms and conditions of the license
 *  contract and with the inclusion of this copyright notice.
 *
 *  Unauthorized copying of this file or any part thereof, via any medium
 *  is strictly prohibited, and may not be provided or otherwise
 *  made available to any third party without Capgemini Technology Services
 *  consent.
 *
 *  No ownership title to the software is transferred hereby. This copyright
 *  notice shall be included in all copies or portions of the Software.
 *
 *  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 *  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 *  FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE AND NONINFRINGEMENT.
 *  IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
 *  DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
 *  OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
 *  USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { store } from "utils/store";
import { mainViewerAction } from "actions/MainViewerAction";
import { getToken } from "services/ForgeService";
import { getIdfromTag } from "services/ObjectService";
import _ from "lodash";

const Autodesk = window.Autodesk;
var viewers = [];
var loadedTag;

function launchViewer(urn, tag, id, viewOptions) {
  var viewer, documentId;
  return getToken((error, token) => {
    if (error) {
      console.error(error);
      return store
        .getState()
        .snackBar.addSnackError(
          store.getState().multiLang.forgeViewerHelper.authenticateError
        );
    }
    const options = Autodesk.Viewing.createInitializerOptions();
    documentId = "urn:" + urn;
    options.env = "AutodeskProduction";
    options.language =
      localStorage.getItem("language") != null
        ? localStorage.getItem("language")
        : "en";
    options.getAccessToken = function (onSuccess) {
      onSuccess(token.access_token, token.expires_in);
    };

    Autodesk.Viewing.Initializer(options, () => {
      viewer = new Autodesk.Viewing.ViewingApplication(id);
      const config = Autodesk.Viewing.createViewerConfig();
      config.extensions = [
        "DTPButtonGroupExtension",
        "IconMarkupExtension",
        "Autodesk.Viewing.MarkupsCore",
        "Autodesk.AEC.Minimap3DExtension",
        "Autodesk.AEC.LevelsExtension"
      ];
      viewer.registerViewer(
        viewer.k3D,
        Autodesk.Viewing.Private.GuiViewer3D,
        config
      );
      viewer.loadDocument(
        documentId,
        onDocumentLoadSuccess,
        onDocumentLoadError
      );
      viewers.push(viewer);
      loadedTag = tag;
      store.dispatch({
        type: mainViewerAction.initViewer,
        viewer: {
          id: id,
          options: viewOptions,
          viewer: viewer,
          viewerType: "FORGE_VIEWER",
          urn: urn,
          lastToken: token.access_token,
          focusOnObjects: focusOnObjects,
          focusOnObjectsByIds: focusOnObjectsByIds,
          hideObjects: hideObjects,
          hideObjectsByIds: hideObjectsByIds,
          isolateObjects: isolateObjects,
          isolateObjectsByIds: isolateObjectsByIds,
          showObjects: showObjects,
          showObjectById: showObjectById,
          selectObject: selectObject,
          selectObjectsById: selectObjectsById
        }
      });
    });
  });
}

/**
 * Autodesk.Viewing.ViewingApplication.loadDocument() success callback.
 * Proceeds with model initialization.
 * @param {Autodesk.Viewing.Document} doc - loaded document
 */
function onDocumentLoadSuccess(doc) {
  var viewer = viewers.find((v) => _.isEqual(v.myDocument, doc));
  // We could still make use of Document.getSubItemsWithProperties()
  // However, when using a ViewingApplication, we have access to the **bubble** field,
  // which references the root node of a graph that wraps each object from the Manifest JSON
  const viewables = viewer.bubble.search({ type: "geometry" });

  if (viewables.length === 0) {
    console.warn("Document contains no viewables");
    return;
  }
  // Choose first avialble viewable
  viewer.selectItem(viewables[0].data, onItemLoadSuccess, onItemLoadError);

  doc.downloadAecModelData();
  let newViewer=viewer.getCurrentViewer();
  newViewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT/Autodesk.Viewing.GEOMETRY_LOADED_EVENT , //wait till design data is loaded
      newViewer.loadExtension('Autodesk.AEC.LevelsExtension')
  );
}

/**
 * Autodesk.Viewing.ViewingApplication.loadDocument() failure callback.
 * @param {number} errorCode
 */
function onDocumentLoadError(errorCode) {
  console.error(`onDocumentLoadError: ${errorCode}`);
}

/**
 * ViewingApplication.selectItem() success callback.
 * Invoked after the model's SVF has been initially loaded.
 * It may trigger before any geometry has been downloaded and displayed on-screen.

 * @param {Object} viewer 
 * @param {Object} item 
 */
function onItemLoadSuccess(viewerIn, item) {
  /*
    viewerIn.getObjectTree(function (objTree) {
        console.log(objTree);
    });
*/ var viewer = viewers.find((v) => _.isEqual(v.myCurrentViewer, viewerIn));
  viewerIn.setTheme("light-theme");
  viewerIn.setBackgroundColor(255, 255, 255, 255, 255, 255);
  viewerIn.setEnvMapBackground(false);
  //console.debug('onItemLoadSuccess');

  viewer.myCurrentViewer.autocam.shotParams.destinationPercent = 3;
  viewer.myCurrentViewer.autocam.shotParams.duration = 3;
  if (loadedTag) {
    focusOnObjects(loadedTag, viewer.appContainerId);
    loadedTag = null;
  }
}

/**
 * ViewingApplication.selectItem() failure callback
 * @param {number} errorCode
 */
function onItemLoadError(errorCode) {
  console.error(`onItemLoadError: ${errorCode}`);
}

async function focusOnObjects(tag, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  const modelId = viewerId.split("#")[1];
  const revision = viewerId.split("#")[2];
  const projectId = store.getState().project.projectId;
  const forgeViewer = "FORGE_VIEWER";
  if (tag) {
    const { data } = await getIdfromTag(
      projectId,
      modelId,
      revision,
      tag,
      forgeViewer
    );
    const ids = [];
    data.forEach((obj) => {
      if (obj.Id) {
        ids.push(parseInt(obj.Id));
      }
    });
    // to not get an error if the model is not already loaded
    if (viewer.myCurrentViewer) {
      viewer.myCurrentViewer.fitToView(ids, viewer.myCurrentViewer.model);
      viewer.myCurrentViewer.select(ids);
      return ids;
    }
    return null;
  } else {
    // Rest viewer
    if (viewer.myCurrentViewer) {
      viewer.myCurrentViewer.select([]);
    }
    return null;
  }
}

function focusOnObjectsByIds(ids, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  if (!Array.isArray(ids)) {
    return;
  }
  sessionStorage.setItem("tagSelectionSource", "others");
  viewer.myCurrentViewer.fitToView(ids, viewer.myCurrentViewer.model);
  viewer.myCurrentViewer.select(ids);
}

function selectObject(tag, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  const projectId = store.getState().project.projectId;
  const modelId = viewerId.split("#")[1];
  const revision = viewerId.split("#")[2];
  const forgeViewer = "FORGE_VIEWER";

  getIdfromTag(
    projectId,
    modelId,
    revision,
    tag,
    forgeViewer,
    (error, data) => {
      if (error) {
        console.error(error);
        return;
      }

      const ids = [];

      // tags not found in viewer
      if (data.length === 0) {
        const { snackBar, multiLang } = store.getState();
        viewer.myCurrentViewer.clearSelection();
        return snackBar.addSnackWarning(
          multiLang.breakdownPanel.notVisibleInViewer
        );
      }

      data.forEach((obj) => {
        if (obj.Id) {
          ids.push(parseInt(obj.Id));
        }
      });

      viewer.myCurrentViewer.select(ids);
    }
  );
}

/**
 *  This function accepts an array of integers or an integer as parameter
 */
function selectObjectsById(ids, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  if (!Array.isArray(ids) || ids.find((id) => !Number.isInteger(id))) {
    return;
  }
  viewer.myCurrentViewer.select(ids);
}

function isolateObjects(tag, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  const projectId = store.getState().project.projectId;
  const modelId = viewerId.split("#")[1];
  const revision = viewerId.split("#")[2];
  const forgeViewer = "FORGE_VIEWER";

  getIdfromTag(
    projectId,
    modelId,
    revision,
    tag,
    forgeViewer,
    (error, data) => {
      if (error) {
        console.error(error);
        return;
      }

      const ids = [];
      data.forEach((obj) => {
        if (obj.Id) {
          ids.push(parseInt(obj.Id));
        }
      });

      viewer.myCurrentViewer.isolate(ids);
      viewer.myCurrentViewer.select(ids);
    }
  );
}

function isolateObjectsByIds(ids, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  if (!Array.isArray(ids)) {
    return;
  }
  sessionStorage.setItem("tagSelectionSource", "others");
  viewer.myCurrentViewer.isolate(ids);
  viewer.myCurrentViewer.select(ids);
}

function hideObjects(tag, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  const projectId = store.getState().project.projectId;
  const modelId = viewerId.split("#")[1];
  const revision = viewerId.split("#")[2];
  const forgeViewer = "FORGE_VIEWER";

  getIdfromTag(
    projectId,
    modelId,
    revision,
    tag,
    forgeViewer,
    (error, data) => {
      if (error) {
        console.error(error);
        return;
      }

      const ids = [];
      data.forEach((obj) => {
        if (obj.Id) {
          ids.push(parseInt(obj.Id));
        }
      });

      viewer.myCurrentViewer.hide(ids);
    }
  );
}

function hideObjectsByIds(ids, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  if (!Array.isArray(ids)) {
    return;
  }
  sessionStorage.setItem("tagSelectionSource", "others");
  viewer.myCurrentViewer.hide(ids);
}

function showObjects(tag, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  const projectId = store.getState().project.projectId;
  const modelId = viewerId.split("#")[1];
  const revision = viewerId.split("#")[2];
  const forgeViewer = "FORGE_VIEWER";

  getIdfromTag(
    projectId,
    modelId,
    revision,
    tag,
    forgeViewer,
    (error, data) => {
      if (error) {
        console.error(error);
        return;
      }

      const ids = [];
      data.forEach((obj) => {
        if (obj.Id) {
          ids.push(parseInt(obj.Id));
        }
      });

      viewer.myCurrentViewer.show(ids);
    }
  );
}

/**
 *  This function accepts an array of integers or an integer as parameter
 */
function showObjectById(ids, viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  if (!Array.isArray(ids) || !Number.isInteger(ids)) {
    return;
  }
  viewer.myCurrentViewer.show(ids);
}

function unloadViewer(viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  _.remove(viewers, { appContainerId: viewerId });
  if (viewer) viewer.finish();
}

function restoreState(state, viewerId) {
  const viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  viewer.myCurrentViewer.getExtension("Autodesk.Viewing.MarkupsCore").hide();
  // Restore the camera state of the markup with the third argument to false to a smooth transition
  viewer.myCurrentViewer.restoreState(state, null, true);
}

function loadMarkups(graphics, markupId, viewerId) {
  const viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  let markup = viewer.myCurrentViewer.getExtension(
    "Autodesk.Viewing.MarkupsCore"
  );
  markup.show();
  markup.loadMarkups(graphics, "layer" + markupId);
}

function hideMarkups(viewerId) {
  const viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  let markup = viewer.myCurrentViewer.getExtension(
    "Autodesk.Viewing.MarkupsCore"
  );
  markup.hide();
}

function enterEditMode(viewerId) {
  var viewer = viewers.find((v) => _.isEqual(v.appContainerId, viewerId));
  const buttonGroup = new Autodesk.Viewing.UI.ControlGroup(
    "MarkupExtension.ControlGroup3"
  );

  var markupExtension = viewer.myCurrentViewer.getExtension(
    "Autodesk.Viewing.MarkupsCore"
  );

  let _btnArrow = new Autodesk.Viewing.UI.Button("Arrow");
  _btnArrow.addClass("button-arrow");
  _btnArrow.setToolTip(store.getState().multiLang.markup.arrowMarkup);
  _btnArrow.onClick = (e) => {
    var arrow = new Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow(
      markupExtension
    );
    markupExtension.changeEditMode(arrow);
  };

  let _btnCircle = new Autodesk.Viewing.UI.Button("Circle");
  _btnCircle.addClass("button-circle");
  _btnCircle.setToolTip(store.getState().multiLang.markup.circleMarkup);
  _btnCircle.onClick = (e) => {
    var circle = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCircle(
      markupExtension
    );
    markupExtension.changeEditMode(circle);
  };

  // let _btnCloud = new Autodesk.Viewing.UI.Button("Cloud");
  // _btnCloud.addClass("button-cloud");
  // _btnCloud.setToolTip(store.getState().multiLang.markup.cloudMarkup);
  // _btnCloud.onClick = (e) => {
  //   var cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCloud(
  //     markupExtension
  //   );
  //   markupExtension.changeEditMode(cloud);
  // };

  let _btnFreehand = new Autodesk.Viewing.UI.Button("Freehand");
  _btnFreehand.addClass("button-freehand");
  _btnFreehand.setToolTip(store.getState().multiLang.markup.freehandMarkup);
  _btnFreehand.onClick = (e) => {
    var freehand =
      new Autodesk.Viewing.Extensions.Markups.Core.EditModeFreehand(
        markupExtension
      );
    markupExtension.changeEditMode(freehand);
  };

  let _btnHighlight = new Autodesk.Viewing.UI.Button("Highlight");
  _btnHighlight.addClass("button-highlight");
  _btnHighlight.setToolTip(store.getState().multiLang.markup.hightlightMarkup);
  _btnHighlight.onClick = (e) => {
    var highlight =
      new Autodesk.Viewing.Extensions.Markups.Core.EditModeHighlight(
        markupExtension
      );
    markupExtension.changeEditMode(highlight);
  };

  let _btnPolyline = new Autodesk.Viewing.UI.Button("Polyline");
  _btnPolyline.addClass("button-polyline");
  _btnPolyline.setToolTip(store.getState().multiLang.markup.polylineMarkup);
  _btnPolyline.onClick = (e) => {
    var polyline =
      new Autodesk.Viewing.Extensions.Markups.Core.EditModePolyline(
        markupExtension
      );
    markupExtension.changeEditMode(polyline);
  };

  // let _btnRectangle = new Autodesk.Viewing.UI.Button("Rectangle");
  // _btnRectangle.addClass("button-rectangle");
  // _btnRectangle.setToolTip(store.getState().multiLang.markup.rectangleMarkup);
  // _btnRectangle.onClick = (e) => {
  //   var rectangle =
  //     new Autodesk.Viewing.Extensions.Markups.Core.EditModeRectangle(
  //       markupExtension
  //     );
  //   markupExtension.changeEditMode(rectangle);
  // };

  let _btnText = new Autodesk.Viewing.UI.Button("Text");
  _btnText.addClass("button-text");
  _btnText.setToolTip(store.getState().multiLang.markup.textMarkup);
  _btnText.onClick = (e) => {
    var text = new Autodesk.Viewing.Extensions.Markups.Core.EditModeText(
      markupExtension
    );
    markupExtension.changeEditMode(text);
  };

  buttonGroup.addControl(_btnArrow);
  buttonGroup.addControl(_btnCircle);
  //buttonGroup.addControl(_btnCloud);
  buttonGroup.addControl(_btnFreehand);
  buttonGroup.addControl(_btnHighlight);
  buttonGroup.addControl(_btnPolyline);
  //buttonGroup.addControl(_btnRectangle);
  buttonGroup.addControl(_btnText);

  markupExtension.enterEditMode();

  viewer.myCurrentViewer.toolbar.addControl(buttonGroup);
}

const forgeViewerHelper = {
  launchViewer,
  unloadViewer,
  focusOnObjects,
  loadMarkups,
  restoreState,
  enterEditMode,
  isolateObjects,
  focusOnObjectsByIds,
  hideObjectsByIds,
  isolateObjectsByIds,
  showObjects,
  showObjectById,
  selectObject,
  selectObjectsById,
  hideMarkups
};

export default forgeViewerHelper;
